import React, { useEffect, useRef } from "react";

function TickerTapeWidget() {
  const container = useRef(null);

  useEffect(() => {
    // Check if the widget is already initialized
    if (container.current.querySelector("script")) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    script.innerHTML = `
      {
        "symbols": [
          { "proName": "FOREXCOM:SPXUSD", "title": "S&P 500" },
          { "proName": "FOREXCOM:NSXUSD", "title": "US 100" },
          { "proName": "FX_IDC:EURUSD", "title": "EUR/USD" },
          { "proName": "BITSTAMP:BTCUSD", "title": "Bitcoin" },
          { "proName": "BITSTAMP:ETHUSD", "title": "Ethereum" }
        ],
        "colorTheme": "light",
        "isTransparent": false,
        "displayMode": "adaptive",
        "locale": "en"
      }`;
    container.current.appendChild(script);
  }, []); // Ensure this runs only once

  return (
    <>
    <div className="gold-rate-1">
      <div className="tradingview-widget-container" ref={container}></div>;
    </div>
    </>
  ) 
}

export default TickerTapeWidget;
