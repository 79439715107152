import React, { useEffect, useRef, useState, memo } from 'react';

function TradingViewWidget({ symbol = 'OANDA:XAUUSD|1D' }) {
  const container = useRef();
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const [widgetError, setWidgetError] = useState(false); // Track errors

  useEffect(() => {
    const cleanUpWidget = () => {
      if (container.current) {
        const existingScript = container.current.querySelector('script');
        if (existingScript) {
          container.current.removeChild(existingScript);
        }
      }
    };

    cleanUpWidget(); // Clean up the previous widget

    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js';
    script.type = 'text/javascript';
    script.async = true;

    // Set widget config
    script.innerHTML = `
      {
        "symbols": [
          [
            "${symbol}"
          ]
        ],
        "chartOnly": false,
        "width": "300",
        "height": "100%",
        "locale": "en",
        "colorTheme": "light",
        "autosize": false,
        "showVolume": false,
        "showMA": false,
        "hideDateRanges": true,
        "hideMarketStatus": false,
        "hideSymbolLogo": false,
        "scalePosition": "right",
        "scaleMode": "Normal",
        "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
        "fontSize": "10",
        "noTimeScale": false,
        "valuesTracking": "1",
        "changeMode": "price-and-percent",
        "chartType": "area",
        "maLineColor": "#2962FF",
        "maLineWidth": 1,
        "maLength": 9,
        "headerFontSize": "medium",
        "lineWidth": 2,
        "lineType": 0
      }`;

    script.onload = () => {
      setWidgetLoaded(true); // Set widget to loaded on successful script load
      setWidgetError(false);  // Reset error state if script loads correctly
    };

    // Handle script load failure
    script.onerror = (error) => {
      console.error('Error loading TradingView script:', error);
      setWidgetError(true);  // Set error state if script fails to load
      setWidgetLoaded(false); // Set widget as not loaded
    };

    // Append the script to the container
    if (container.current) {
      container.current.appendChild(script);
    }

    // Cleanup the widget on component unmount
    return () => {
      setWidgetLoaded(false); // Reset loaded state
      setWidgetError(false);   // Reset error state
      cleanUpWidget(); // Clean up the previous widget
    };
  }, [symbol]); // Re-run effect when symbol changes

  return (
    <div className='gold-rate-0'>
    <div className="tradingview-widget-container m-auto tradingview-widget-container-0" ref={container} >
      {!widgetLoaded && !widgetError && <div>Loading...</div>} {/* Show loading text */}
      {widgetError && <div>Error loading widget. Please try again later.</div>} {/* Show error message */}
      <div className="tradingview-widget-container__widget"></div>
    </div>
    </div>
  );
}

export default memo(TradingViewWidget);
