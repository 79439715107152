import React, { useEffect } from "react";
import Navbar from "./for-all-component/navbar/navbar";
// import Services from "./home/Services/services";
import Home1 from "./home-component/home.js";
// import Services2 from "./home/services2/services";
// import Partners from "./home/partners/partners";
import Footer from "./for-all-component/footer/footer";
// import Web from "./home/web-services/web-s";
// import ServicesPage from "./framer-m";
// import Example from "./framer-motion";
// import Bg from "./home-component/background/particle-bg.js";

function Home() {
  return (
    <div>
      <Navbar />
      <Home1 />
      {/* <Bg /> */}
      <Footer />
    </div>
  );
}

export default Home;
